html {
  box-sizing: border-box;
  width: 100vw;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
    'Helvetica Neue', sans-serif;
  color: #212121;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

/*
 * Стили компонента App
 */
.App {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  padding-bottom: 24px;
}

/*
 * Стили компонента Searchbar
 */
.Searchbar {
  top: 0;
  left: 0;
  position: sticky;
  z-index: 1100;
  display: flex;
  /* justify-content: left; */
  align-items: center;
  min-height: 64px;
  margin-bottom: 15px;
  padding-right: 24px;
  padding-left: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #fff;
  /* background-color: #3f51b5; */
  /* box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12); */
}

/*
 * Стили компонента SearchForm
 */
.SearchForm {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid tomato;
}

.SearchForm-button {
  display: inline-block;
  width: 48px;
  height: 48px;
  border: 0;
  background-image: url('https://image.flaticon.com/icons/svg/149/149852.svg');
  background-size: 40%;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.6;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1);
  cursor: pointer;
  outline: none;
}

.SearchForm-button:hover {
  opacity: 1;
}

.SearchForm-button-label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.SearchForm-input {
  display: inline-block;
  width: 100%;
  font: inherit;
  font-size: 20px;
  border: none;
  outline: none;
  padding-left: 4px;
  padding-right: 4px;
}

.SearchForm-input::placeholder {
  font: inherit;
  font-size: 18px;
}

/*
 * Стили компонента ImageGallery
 */
.ImageGallery {
  display: grid;
  max-width: calc(100vw - 48px);
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 16px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  list-style: none;
  margin-left: auto;
  margin-right: auto;
}

/*
 * Стили компонента ImageGalleryItem
 */
.ImageGalleryItem {
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.ImageGalleryItem-image {
  width: 100%;
  height: 260px;
  object-fit: cover;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

.ImageGalleryItem-image:hover {
  transform: scale(1.03);
  cursor: zoom-in;
}

/*
 * Стили компонента Modal
 */

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1200;
}

.Modal {
  /* position: relative; */
  max-width: calc(100vw - 48px);
  max-height: calc(100vh - 24px);
  width: auto;
  height: 100%;
}
.Modal img {
  max-width: 100%;
  max-height: 100%;
}

/*
* Стили компонента Buton (Load more)
*/
.Button {
  display: block;
  padding: 8px 16px;
  /* margin: 15px auto; */
  border-radius: 2px;
  background-color: #3f51b5;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
  color: #fff;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  font-family: inherit;
  font-size: 18px;
  line-height: 24px;
  font-style: normal;
  font-weight: 500;
  min-width: 180px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.Button:hover,
.Button:focus {
  background-color: #303f9f;
}
.loader svg {
  display: block;
  margin: 0 auto;
}
.loaderModal svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* New */
.NavLink {
  font-size: 24px;
  color: #303f9f;
}
.NavLink--active {
  font-weight: bold;
  color: brown;
}
.MoviesList a {
  color: #303f9f;
  text-decoration: none;
}
.MoviesList a:visited {
  color: #303f9f;
}
.Card img {
  width: 300px;
}
.FacesList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
}
.FacesList li {
  height: 320px;
  flex-basis: 12.5%;
  padding: 0 10px;
  /* border: 1px solid tomato; */
}
.FacesList img {
  display: block;
  width: 100%;
  height: auto;
}

.Container {
  max-width: 1280px;
  margin: 0 auto;
}
.NavClass a {
  margin: 5px;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  line-height: 1.8;
  width: 100px;
  height: 50px;
  border: 1px solid tomato;
  border-radius: 5px;
}
